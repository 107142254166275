<template>
    <div style="background:#fff;">
        <a-layout-content style="padding: 20px">
            <a-layout style="background: #fff">
                <a-layout-header style="height: initial; background: #fff; padding: 0">
                    <a-form layout="horizontal" style="padding: 0 0 10px 0px; height: auto">
                        <a-form-item style="margin-bottom: 10px">
                            <a-form layout="inline">
                                <a-form-item>
                                    <a-row type="flex"
                                           style="padding: 10px"
                                           justify="space-between">
                                        <a-col :span="24" style="padding-left: 10px;padding-bottom:10px">
                                            <a-button type="primary" @click="() => (modalVisible = true)">配置规则</a-button>
                                            <a-modal width="700px"
                                                     v-model="modalVisible"
                                                     title="配置激励规则">
                                                <div>
                                                    <div>
                                                        每升1级奖励
                                                        <a-input :value="partnerAward.levalPrize"
                                                                 style="width: 100px"
                                                                 v-model="partnerAward.levelPrize"
                                                                 placeholder=""></a-input>
                                                        元
                                                    </div>
                                                    <div>
                                                        每升1星奖励
                                                        <a-input :value="partnerAward.starPrize"
                                                                 style="width: 100px"
                                                                 v-model="partnerAward.starPrize"
                                                                 placeholder=""></a-input>
                                                        元
                                                    </div>
                                                    <p style="text-align: center">
                                                        <a-button type="primary"
                                                                  class="margin-r"
                                                                  @click="editAwardPrize"
                                                                  ghost>保存</a-button>
                                                    </p>
                                                </div>
                                                <div slot="footer"></div>
                                            </a-modal>
                                        </a-col>
                                        <a-col :span="48" align="right" style="padding-left: 10px">
                                            <a-radio-group defaultValue="-1"
                                                           @change="radioChange"
                                                           button-style="solid">
                                                <a-radio-button value="-1">全部</a-radio-button>
                                                <a-radio-button value="0">升星激励</a-radio-button>
                                                <a-radio-button value="1">升级激励</a-radio-button>
                                            </a-radio-group>
                                            <label style="margin-left: 20px">时间 </label>
                                            <a-range-picker style="width: 260px"
                                                            :value="Time"
                                                            @change="handleChange" />
                                            <a-input contextmenu="2455"
                                                     :value="name"
                                                     style="width: 260px; margin-left: 20px"
                                                     v-model="searchVal"
                                                     placeholder="输入关键词搜索"></a-input>
                                            <a-button type="primary" style="margin-left:10px" @click="getTableData">查询</a-button>
                                        </a-col>
                                    </a-row>
                                </a-form-item>
                            </a-form>
                        </a-form-item>
                    </a-form>
                </a-layout-header>
                <a-layout-content>
                    <div class="yj_list">
                        <a-table :columns="columns"
                                 bordered
                                 rowKey="ID"
                                 :data-source="tableData"
                                 :pagination="pagination"
                                 @change="pagerChange"
                                 class="yj-table-img"
                                 style="min-height: calc(100vh - 314px)">
                            <span slot="action" slot-scope="text, record">
                                <a>{{ record.PubState == 0 ? "活动发布" : "活动结束" }}</a>
                            </span>
                        </a-table>
                    </div>
                </a-layout-content>
            </a-layout>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns: [
                    {
                        title: "团队",
                        dataIndex: "Name",
                    },
                    {
                        title: "奖励类型",
                        customRender: (text, item) => {
                            if (item.AwardPartnerType == 1) {
                                return "升级";
                            }
                            if (item.AwardPartnerType == 0) {
                                return "升星";
                            }
                        },
                    },
                    {
                        title: "原星级",
                        dataIndex: "OrinalStar",
                    },
                    {
                        title: "新星级",
                        dataIndex: "NewStar",
                    },
                    {
                        title: "原等级",
                        dataIndex: "OrinalLevel",
                    },
                    {
                        title: "新等级",
                        dataIndex: "NewLevel",
                    },
                    {
                        title: "奖励金额",
                        dataIndex: "AwardMoney",
                    },
                    {
                        title: "时间",
                        customRender: (text, item) => {
                            var temp = new Date(
                                util.TimeStamptoDateTime(item.CreateTime, "yyyy-MM-dd hh:mm:ss")
                            );

                            return util.formatDate3(temp);
                        },
                    },
                    {
                        title: "摘要",
                        dataIndex: "Summary",
                    },
                ],
                radioVal: -1,
                searchVal: "",
                partnerAward: {
                    levelPrize: "1",
                    starPrize: "2",
                },
                modalVisible: false,
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: (total) => `共 ${total} 条数据`,
                },
                Time: [],
                BeginTime: "",
                EndTime: "",
                PublishStatus: "-1",
                PartnerID: "",
                name: "",
            };
        },
        props: {
            //组件属性
        },
        methods: {
            //方法
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            handleChange(value) {
                this.Time = value;
                if (value.length == 0) {
                    this.BeginTime = "";
                    this.EndTime = "";
                } else {
                    this.BeginTime = util.formatDate(this.Time[0]._d);
                    this.EndTime = util.formatDate(this.Time[1]._d);
                }
            },
            radioChange: function (e) {
                console.log("e:", e);
                var self = this;
                self.radioVal = e.target.value;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetPromoteStimulatePartnerList",
                    data: {
                        pageIndex: self.pagination.current,
                        name: self.searchVal,
                        type: self.radioVal,
                        begin: self.BeginTime,
                        end: self.EndTime,
                        PartnerID: self.PartnerID,
                    },
                    OnSuccess: function (data) {
                        //console.log("data-1:", data);
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    },
                };
                //console.log(op);
                http.Post(op);
            },

            getAwardPrize: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetPromoteStimulatePartnerConfigDetail",
                    data: {},
                    OnSuccess: function (data) {
                        console.log(data);
                        self.partnerAward.levelPrize = data.data.LevelMoney;
                        self.partnerAward.starPrize = data.data.StarMoney;
                    },
                };
                http.Post(op);
            },

            editAwardPrize: function () {
                var self = this;
                //self.$message.error("");
                var op = {
                    url: "/MarketingModule/Promote/EditStimulatePartnerConfig",
                    data: {
                        levelPrize: self.partnerAward.levelPrize,
                        starPrize: self.partnerAward.starPrize,
                    },
                    OnSuccess: function (data) {
                        if (data.data == true) {
                            self.$message.success("保存成功");
                            self.modalVisible = false;
                        }
                    },
                };
                console.log(op);
                http.Post(op);
            },
        },

        mounted: function () {
            //生命周期，组装完成
            this.PartnerID = this.$route.query.PartnerID;
            this.getTableData();
            this.getAwardPrize();
        },
    };
</script>